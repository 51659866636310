import React, { useState, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth, isSameDay, isToday, parseISO } from 'date-fns';

interface DatePickerProps {
  value: string;
  onChange: (date: string) => void;
  max?: string;
  min?: string;
  label?: string;
  error?: string;
  helperText?: string;
  className?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  max,
  min,
  label,
  error,
  helperText,
  className = '',
}) => {
  const [currentMonth, setCurrentMonth] = useState(value ? new Date(value) : new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value));
      setCurrentMonth(new Date(value));
    }
  }, [value]);

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
    onChange(format(date, 'yyyy-MM-dd'));
    setOpen(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-center justify-between px-2 py-2 mb-2 border-b border-gray-700">
        <button
          type="button"
          onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
          className="p-1.5 rounded-full hover:bg-purple-600/30 focus:outline-none transition-colors"
        >
          <svg className="w-5 h-5 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="text-gray-200 font-medium text-sm">
          {format(currentMonth, 'MMMM yyyy')}
        </div>
        <button
          type="button"
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
          className="p-1.5 rounded-full hover:bg-purple-600/30 focus:outline-none transition-colors"
        >
          <svg className="w-5 h-5 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    );
  };

  const renderDays = () => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return (
      <div className="grid grid-cols-7 gap-1 mb-2">
        {days.map((day) => (
          <div key={day} className="text-center text-xs text-purple-400 font-medium py-1">
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    const maxDate = max ? parseISO(max) : undefined;
    const minDate = min ? parseISO(min) : undefined;

    const isDisabled = (date: Date) => {
      if (maxDate && date > maxDate) return true;
      if (minDate && date < minDate) return true;
      return false;
    };

    const daysArray = eachDayOfInterval({ start: startDate, end: endDate });

    for (let i = 0; i < daysArray.length; i++) {
      day = daysArray[i];
      formattedDate = format(day, dateFormat);
      
      const cloneDay = day;
      const isSelected = selectedDate ? isSameDay(day, selectedDate) : false;
      const disabled = isDisabled(day);
      
      days.push(
        <div
          key={day.toString()}
          className={`text-center py-1.5 text-sm rounded-md cursor-pointer transition-colors
            ${!isSameMonth(day, monthStart) ? 'text-gray-600' : 'text-gray-200'} 
            ${isSelected ? 'bg-purple-600 text-white font-medium' : ''} 
            ${isToday(day) && !isSelected ? 'border border-purple-500' : ''}
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-600/20'}
          `}
          onClick={() => !disabled && onDateChange(cloneDay)}
        >
          {formattedDate}
        </div>
      );

      if ((i + 1) % 7 === 0 || i === daysArray.length - 1) {
        rows.push(
          <div key={day.toString()} className="grid grid-cols-7 gap-1 mb-1">
            {days}
          </div>
        );
        days = [];
      }
    }

    return <div className="mt-1">{rows}</div>;
  };

  return (
    <div className={`relative ${className}`}>
      {label && (
        <label className="block text-sm font-medium text-gray-300 mb-1">{label}</label>
      )}
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <div className="relative">
            <input
              type="text"
              readOnly
              value={selectedDate ? format(selectedDate, 'MM/dd/yyyy') : ''}
              placeholder="Select a date"
              className={`w-full date-input-custom cursor-pointer ${error ? 'border-red-500' : ''}`}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg 
                className={`w-5 h-5 ${error ? 'text-red-500' : 'text-purple-400'}`} 
                fill="currentColor" 
                viewBox="0 0 20 20" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  fillRule="evenodd" 
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" 
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content 
            className="bg-gray-800/95 backdrop-blur-sm border border-purple-500/30 rounded-lg shadow-xl p-4 w-72 animate-slideDownAndFade z-50"
            sideOffset={5}
            align="center"
          >
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            <Popover.Arrow className="fill-purple-500/30" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {error ? (
        <p className="mt-1 text-xs text-red-500">{error}</p>
      ) : helperText ? (
        <p className="mt-1 text-xs text-gray-400">{helperText}</p>
      ) : null}
    </div>
  );
};

export default DatePicker; 