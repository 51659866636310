import React, { useState, useEffect, useCallback } from 'react';
import { trackEvent } from '../utils/analytics';
import axios from 'axios';

interface StockData {
  date: string;
  close: number;
}

interface Stock {
  symbol: string;
  returns: number;
  volatility: number;
  data: StockData[];
}

interface FinanceProject3Props {
  initialPortfolio?: { ticker: string; shares: number }[];
}

const FinanceProject3: React.FC<FinanceProject3Props> = ({ initialPortfolio = [] }) => {
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [riskTolerance, setRiskTolerance] = useState<'low' | 'medium' | 'high'>('medium');
  const [newStock, setNewStock] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStockData = useCallback(async (symbol: string): Promise<Stock> => {
    try {
      console.log(`Fetching data for ${symbol} from /stock-data`);
      const response = await axios.get(`/stock-data`, {
        params: { symbol },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      console.log(`Received data for ${symbol}:`, response.data);

      // Check if the response contains an error
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      const data: StockData[] = response.data;
      if (!data || data.length === 0) {
        throw new Error(`No data available for ${symbol}`);
      }

      const returns = calculateReturns(data);
      const volatility = calculateVolatility(data);

      return {
        symbol,
        returns,
        volatility,
        data
      };
    } catch (error) {
      console.error(`Error fetching data for ${symbol}:`, error);
      
      // Extract the most useful error message
      let errorMessage = `Error fetching data for ${symbol}`;
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      }
      
      throw new Error(errorMessage);
    }
  }, []);

  const calculateReturns = (data: StockData[]): number => {
    const firstPrice = data[0].close;
    const lastPrice = data[data.length - 1].close;
    return (lastPrice / firstPrice) - 1;
  };

  const calculateVolatility = (data: StockData[]): number => {
    const returns = data.slice(1).map((d, i) => Math.log(d.close / data[i].close));
    const meanReturn = returns.reduce((sum, r) => sum + r, 0) / returns.length;
    const squaredDifferences = returns.map(r => Math.pow(r - meanReturn, 2));
    const variance = squaredDifferences.reduce((sum, sd) => sum + sd, 0) / (returns.length - 1);
    return Math.sqrt(variance * 252); // Annualized volatility
  };

  const addStock = async () => {
    if (newStock) {
      setIsLoading(true);
      setError(null);
      try {
        const stockData = await fetchStockData(newStock);
        setStocks(prevStocks => [...prevStocks, stockData]);
        setNewStock('');
        trackEvent('add_stock', JSON.stringify({ project: 'portfolio_allocator', stock: newStock }));
      } catch (error) {
        setError(`Failed to add stock ${newStock}: ${error instanceof Error ? error.message : 'Unknown error'}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const calculateAllocation = () => {
    if (stocks.length === 0) return [];

    const riskFreeRate = 0.02; // Assuming 2% risk-free rate

    // Minimum Variance Portfolio (for low risk)
    const inverseVariances = stocks.map(stock => 1 / (stock.volatility * stock.volatility));
    const totalInverseVariance = inverseVariances.reduce((sum, inv) => sum + inv, 0);
    const minVarianceWeights = inverseVariances.map(inv => inv / totalInverseVariance);

    // Risk Parity allocation (for medium risk)
    const totalRisk = stocks.reduce((sum, stock) => sum + stock.volatility, 0);
    const riskParityWeights = stocks.map(stock => 1 / stock.volatility / totalRisk);

    // Maximum Returns allocation (for high risk)
    const maxReturnWeights = stocks.map(stock => {
      const excessReturn = stock.returns - riskFreeRate;
      return excessReturn > 0 ? excessReturn : 0; // Only positive excess returns get weight
    });

    // Blend weights based on risk tolerance
    let finalWeights: number[];
    switch (riskTolerance) {
      case 'low':
        finalWeights = minVarianceWeights;
        break;
      case 'medium':
        finalWeights = riskParityWeights;
        break;
      case 'high':
        finalWeights = maxReturnWeights;
        break;
    }

    // Normalize weights to sum to 100%
    const totalWeight = finalWeights.reduce((sum, weight) => sum + weight, 0);
    finalWeights = finalWeights.map(weight => (weight / totalWeight) * 100);

    return stocks.map((stock, index) => ({
      ...stock,
      allocation: finalWeights[index],
    })).sort((a, b) => b.allocation - a.allocation);
  };

  const removeStock = (symbolToRemove: string) => {
    setStocks(stocks.filter(stock => stock.symbol !== symbolToRemove));
    trackEvent('remove_stock', JSON.stringify({ project: 'portfolio_allocator', stock: symbolToRemove }));
  };

  useEffect(() => {
    const loadInitialPortfolio = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const loadedStocks = await Promise.all(
          initialPortfolio.map(stock => fetchStockData(stock.ticker))
        );
        setStocks(loadedStocks);
      } catch (error) {
        setError(`Failed to load initial portfolio: ${error instanceof Error ? error.message : 'Unknown error'}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (initialPortfolio.length > 0) {
      loadInitialPortfolio();
    }
  }, [initialPortfolio, fetchStockData]); // Now it's safe to include fetchStockData

  return (
    <div className="finance-project-3 bg-gradient-to-br from-purple-900 to-black rounded-lg shadow-md p-6 max-w-3xl mx-auto w-full">
      {/* Add this debug information */}
      <div className="text-xs text-gray-400 mb-4">

      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="space-y-4">
        <div className="flex flex-col items-center">
          <label className="block text-sm font-medium text-gray-300">Risk Tolerance</label>
          <select
            value={riskTolerance}
            onChange={(e) => setRiskTolerance(e.target.value as 'low' | 'medium' | 'high')}
            className="mt-1 block w-full max-w-xs rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          >
            <option value="low">Low Risk</option>
            <option value="medium">Medium Risk</option>
            <option value="high">High Risk</option>
          </select>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-2">Add Stock</h3>
          <div className="flex space-x-2 w-full max-w-xs">
            <input
              type="text"
              placeholder="Ticker Symbol"
              value={newStock}
              onChange={(e) => setNewStock(e.target.value.toUpperCase())}
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              disabled={isLoading}
            />
            <button
              onClick={addStock}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              disabled={isLoading}
            >
              {isLoading ? 'Adding...' : 'Add'}
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-2xl">

            <div className="overflow-x-auto bg-gray-800 rounded-lg shadow">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-900">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Symbol</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Allocation</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Returns (3 months)</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Volatility (3 months)</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Action</th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {calculateAllocation().map((stock, index) => (
                    <tr key={index} className="hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">{stock.symbol}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{stock.allocation.toFixed(2)}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{(stock.returns * 100).toFixed(2)}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{(stock.volatility * 100).toFixed(2)}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        <button
                          onClick={() => removeStock(stock.symbol)}
                          className="text-red-400 hover:text-red-300"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceProject3;