import React from 'react';
import AboutMe from '../components/AboutMe';
import * as Tabs from '@radix-ui/react-tabs';

const Home: React.FC = () => {
  return (
    <div className="space-y-8">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-indigo-400">Welcome to My Portfolio</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          Explore my finance projects and tools designed to help with investment analysis and portfolio management.
        </p>
      </div>
      
      <Tabs.Root defaultValue="about" className="w-full">
        <Tabs.List className="flex border-b border-gray-700 mb-6">
          <Tabs.Trigger 
            value="about" 
            className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-purple-500 data-[state=active]:text-purple-400 text-gray-400 hover:text-gray-200 transition-colors"
          >
            About Me
          </Tabs.Trigger>
          <Tabs.Trigger 
            value="skills" 
            className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-cyan-500 data-[state=active]:text-cyan-400 text-gray-400 hover:text-gray-200 transition-colors"
          >
            Skills
          </Tabs.Trigger>
        </Tabs.List>
        
        <Tabs.Content value="about" className="p-1 focus:outline-none">
          <AboutMe />
        </Tabs.Content>
        
        <Tabs.Content value="skills" className="p-1 focus:outline-none">
          <div className="bg-gray-800/50 rounded-lg border border-gray-700/50 p-6">
            <h3 className="text-xl font-semibold mb-4 text-cyan-400">Technical Skills</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-medium mb-2 text-gray-200">Finance</h4>
                <ul className="list-disc list-inside text-gray-300 space-y-1">
                  <li>Investment Analysis</li>
                  <li>Portfolio Management</li>
                  <li>Risk Assessment</li>
                  <li>Financial Modeling</li>
                  <li>Equity Valuation</li>
                </ul>
              </div>
              <div>
                <h4 className="font-medium mb-2 text-gray-200">Technical</h4>
                <ul className="list-disc list-inside text-gray-300 space-y-1">
                  <li>React.js</li>
                  <li>TypeScript</li>
                  <li>Data Visualization</li>
                  <li>API Integration</li>
                  <li>Financial APIs</li>
                </ul>
              </div>
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};

export default Home;
