import React from 'react';
import FinanceProject1 from '../components/FinanceProject1';
import Card from '../components/Card';
import * as Tabs from '@radix-ui/react-tabs';

const Project1: React.FC = () => {
  return (
    <div className="space-y-6">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-indigo-400">Portfolio Backtester</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          Test your investment strategies against historical market data to evaluate performance.
        </p>
      </div>
      
      <Card className="p-6">
        <Tabs.Root defaultValue="backtester" className="w-full">
          <Tabs.List className="flex border-b border-gray-700 mb-6">
            <Tabs.Trigger 
              value="backtester" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-purple-500 data-[state=active]:text-purple-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Backtester
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="instructions" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-purple-500 data-[state=active]:text-purple-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Instructions
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="about" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-purple-500 data-[state=active]:text-purple-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              About
            </Tabs.Trigger>
          </Tabs.List>
          
          <Tabs.Content value="backtester" className="focus:outline-none">
            <FinanceProject1 />
          </Tabs.Content>
          
          <Tabs.Content value="instructions" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-purple-400">How to Use the Backtester</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Enter the stock symbols you want to include in your portfolio</li>
                <li>Set the allocation percentage for each stock</li>
                <li>Select the date range for backtesting</li>
                <li>Click "Run Backtest" to see the results</li>
                <li>Analyze the performance metrics and charts</li>
              </ol>
              <p>
                The backtester will calculate key metrics such as total return, annualized return, 
                maximum drawdown, and Sharpe ratio to help you evaluate your strategy.
              </p>
            </div>
          </Tabs.Content>
          
          <Tabs.Content value="about" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-purple-400">About This Tool</h3>
              <p>
                The Portfolio Backtester is designed to help investors test their investment strategies 
                using historical market data. It provides a way to evaluate how a portfolio would have 
                performed in the past, which can help inform future investment decisions.
              </p>
              <p>
                While past performance is not indicative of future results, backtesting can provide 
                valuable insights into the risk and return characteristics of different investment strategies.
              </p>
              <p className="text-sm text-gray-400 italic">
                Note: This tool is for educational purposes only and should not be considered financial advice.
              </p>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </Card>
    </div>
  );
};

export default Project1;
