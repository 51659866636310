import React from 'react';
import FinanceProject3 from '../components/FinanceProject3';
import Card from '../components/Card';
import * as Tabs from '@radix-ui/react-tabs';

const Project3: React.FC = () => {
  return (
    <div className="space-y-6">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">Portfolio Allocator</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          Optimize your investment portfolio allocation based on risk and return objectives.
        </p>
      </div>
      
      <Card variant="accent" className="p-6">
        <Tabs.Root defaultValue="allocator" className="w-full">
          <Tabs.List className="flex border-b border-gray-700 mb-6">
            <Tabs.Trigger 
              value="allocator" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-indigo-500 data-[state=active]:text-indigo-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Allocator
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="instructions" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-indigo-500 data-[state=active]:text-indigo-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Instructions
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="about" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-indigo-500 data-[state=active]:text-indigo-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              About
            </Tabs.Trigger>
          </Tabs.List>
          
          <Tabs.Content value="allocator" className="focus:outline-none">
            <FinanceProject3 />
          </Tabs.Content>
          
          <Tabs.Content value="instructions" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-indigo-400">How to Use the Portfolio Allocator</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Enter the assets you want to include in your portfolio</li>
                <li>Input expected returns and volatility for each asset</li>
                <li>Set your risk tolerance level</li>
                <li>Click "Generate Allocation" to see the optimal portfolio</li>
                <li>Review the allocation percentages and expected performance</li>
              </ol>
              <p>
                The Portfolio Allocator uses modern portfolio theory to find the optimal asset allocation 
                that maximizes returns for your specified level of risk tolerance.
              </p>
            </div>
          </Tabs.Content>
          
          <Tabs.Content value="about" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-indigo-400">About This Tool</h3>
              <p>
                The Portfolio Allocator is based on Modern Portfolio Theory (MPT), which was developed by 
                Harry Markowitz in 1952. MPT is a mathematical framework for assembling a portfolio of assets 
                such that the expected return is maximized for a given level of risk.
              </p>
              <p>
                This tool helps investors find the optimal allocation of assets in their portfolio by 
                considering the expected returns, volatility, and correlations between different assets.
              </p>
              <p className="text-sm text-gray-400 italic">
                Note: This tool is for educational purposes only and should not be considered financial advice.
              </p>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </Card>
    </div>
  );
};

export default Project3;
