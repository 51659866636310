import React from 'react';

interface Column<T> {
  header: string;
  accessor: keyof T | ((data: T) => React.ReactNode);
  className?: string;
}

interface ModernTableProps<T> {
  data: T[];
  columns: Column<T>[];
  className?: string;
  emptyMessage?: string;
}

function ModernTable<T>({ data, columns, className = '', emptyMessage = 'No data available' }: ModernTableProps<T>) {
  // Ensure data is an array
  const safeData = Array.isArray(data) ? data : data ? [data as unknown as T] : [];
  
  if (!safeData || safeData.length === 0) {
    return (
      <div className="w-full p-4 text-center text-gray-400 bg-gray-800/50 rounded-lg border border-gray-700/50">
        {emptyMessage}
      </div>
    );
  }

  return (
    <div className={`w-full overflow-hidden rounded-lg shadow-md ${className}`}>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-800 border-b border-gray-700">
              {columns.map((column, index) => (
                <th 
                  key={index} 
                  className="px-4 py-3 text-left text-xs font-medium text-purple-400 uppercase tracking-wider"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-800/50 divide-y divide-gray-700">
            {safeData.map((row, rowIndex) => (
              <tr 
                key={rowIndex} 
                className="transition-colors hover:bg-gray-700/50"
              >
                {columns.map((column, colIndex) => {
                  const value = typeof column.accessor === 'function' 
                    ? column.accessor(row) 
                    : row[column.accessor as keyof T];
                  
                  return (
                    <td 
                      key={colIndex} 
                      className={`px-4 py-3 text-sm text-gray-200 ${column.className || ''}`}
                    >
                      {value as React.ReactNode}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ModernTable; 