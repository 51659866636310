import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { trackEvent } from '../utils/analytics';
import ModernChart from './ModernChart';
import ModernTable from './ModernTable';

interface StockData {
  date: string;
  price: number;
}

interface NewsItem {
  headline: string;
}

interface InsiderTrade {
  insider: string;
  shares: number;
  type: string;
}

interface InstitutionalHolder {
  "Date Reported": string;
  Holder: string;
  Shares: number;
  Value: number;
  pctHeld: number;
}

interface EarningsHistory {
  epsActual: number;
  epsDifference: number;
  epsEstimate: number;
  surprisePercent: number;
  quarter: string;
}

interface AnalystRatings {
  buy: number;
  hold: number;
  sell: number;
}

interface ApiResponse {
  stockData: StockData[];
  news: NewsItem[];
  insiderTrades: InsiderTrade[];
  institutionalHolders: InstitutionalHolder[];
  earningsHistory: EarningsHistory[];
  analystRatings: AnalystRatings;
  analystPriceTarget: number;
  error?: string;
}

const FinanceProject2: React.FC = () => {
  const [ticker, setTicker] = useState<string>('');
  const [data, setData] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLongLoadingMessage, setShowLongLoadingMessage] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setShowLongLoadingMessage(true);
      }, 5000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isLoading]);

  const fetchData = async () => {
    if (!ticker) return;
    setIsLoading(true);
    setShowLongLoadingMessage(false);
    setError(null);
    try {
      console.log(`Fetching data for ${ticker}...`);
      const response = await axios.get<ApiResponse>(`/api/stock/${ticker}`);
      console.log('Raw API response:', response.data);
      
      // Check if the response contains an error
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      
      // Validate the response data
      if (!response.data.stockData || !Array.isArray(response.data.stockData) || response.data.stockData.length === 0) {
        throw new Error(`No stock data available for ${ticker}`);
      }
      
      // Ensure all required properties exist
      const safeData: ApiResponse = {
        stockData: response.data.stockData || [],
        news: response.data.news || [],
        insiderTrades: response.data.insiderTrades || [],
        institutionalHolders: response.data.institutionalHolders || [],
        earningsHistory: Array.isArray(response.data.earningsHistory) 
          ? response.data.earningsHistory 
          : response.data.earningsHistory 
            ? [response.data.earningsHistory] // Convert object to array if it's an object
            : [], // Default empty array if undefined
        analystRatings: response.data.analystRatings || {
          buy: 0,
          hold: 0,
          sell: 0
        },
        analystPriceTarget: response.data.analystPriceTarget || 0
      };
      
      setData(safeData);
      trackEvent('fetch_stock_data', JSON.stringify({ project: 'equity_dashboard', ticker }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
      
      // Extract the most useful error message
      let errorMessage = 'Unknown error';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else {
        errorMessage = String(error);
      }
      
      setError(`Error fetching data: ${errorMessage}`);
      trackEvent('fetch_stock_data_error', JSON.stringify({ project: 'equity_dashboard', ticker, error: errorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  const normalizedStockData = useMemo(() => {
    if (!data?.stockData) return [];
    return data.stockData.map(d => ({
      date: d.date,
      price: parseFloat(d.price.toFixed(2)) // Ensure price is rounded to 2 decimal places
    }));
  }, [data?.stockData]);

  return (
    <div className="finance-project-2 bg-gray-800 rounded-lg shadow-md p-6 text-white">
      <h2 className="text-2xl font-bold mb-4 text-center">Stock Dashboard</h2>
      <div className="mb-4 flex justify-center">
        <input
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())}
          placeholder="Enter stock ticker"
          className="mt-1 block w-64 rounded-md border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <button
          onClick={fetchData}
          disabled={isLoading}
          className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-blue-400"
        >
          {isLoading ? 'Loading...' : 'Get Data'}
        </button>
      </div>

      {isLoading && (
        <div className="text-center mt-4">
          <p>Loading data for {ticker}...</p>
          {showLongLoadingMessage && (
            <p className="mt-2 text-yellow-600">
              This request is taking longer than expected. It may take up to 1 minute to fetch the data. Please be patient.
            </p>
          )}
        </div>
      )}

      {error && <p className="text-center text-red-500 mt-4">{error}</p>}

      {data && (
        <div className="flex flex-col items-center">
          <div className="mb-6 w-full">
            {normalizedStockData.length > 0 && (
              <div className="mb-6 w-full">
                <h3 className="text-xl font-semibold mb-2 text-center">Stock Price Chart</h3>
                <ModernChart
                  data={normalizedStockData}
                  height={400}
                  xAxisDataKey="date"
                  yAxisFormatter={(value) => `$${value.toFixed(2)}`}
                  tooltipFormatter={(value, name) => [`$${value.toFixed(2)}`, "Price"]}
                  series={[
                    {
                      dataKey: "price",
                      name: "Price",
                      color: "#8884d8",
                      activeDot: { r: 8 }
                    }
                  ]}
                />
              </div>
            )}
          </div>

          <div className="mt-6 w-full max-w-4xl space-y-8">
            {/* Earnings History Table */}
            <div>
              <h3 className="text-xl font-semibold mb-3 text-center">Earnings History</h3>
              {data.earningsHistory.length > 0 && data.earningsHistory.some(item => 
                item.epsActual !== 0 || item.epsEstimate !== 0 || item.epsDifference !== 0 || item.surprisePercent !== 0
              ) ? (
                <ModernTable
                  data={data.earningsHistory}
                  columns={[
                    {
                      header: "Quarter",
                      accessor: (row) => row.quarter
                    },
                    {
                      header: "EPS Actual",
                      accessor: (row) => `$${row.epsActual.toFixed(2)}`
                    },
                    {
                      header: "EPS Estimate",
                      accessor: (row) => `$${row.epsEstimate.toFixed(2)}`
                    },
                    {
                      header: "Difference",
                      accessor: (row) => `$${row.epsDifference.toFixed(2)}`
                    },
                    {
                      header: "Surprise %",
                      accessor: (row) => `${(row.surprisePercent * 100).toFixed(2)}%`
                    }
                  ]}
                />
              ) : (
                <div className="w-full p-4 text-center text-gray-400 bg-gray-800/50 rounded-lg border border-gray-700/50">
                  No earnings history is currently available for this ticker
                </div>
              )}
            </div>

            {/* Insider Trades Table */}
            <div>
              <h3 className="text-xl font-semibold mb-3 text-center">Insider Trades</h3>
              <ModernTable
                data={data.insiderTrades}
                columns={[
                  {
                    header: "Insider",
                    accessor: "insider"
                  },
                  {
                    header: "Type",
                    accessor: "type"
                  },
                  {
                    header: "Shares",
                    accessor: (row) => typeof row.shares === 'number' ? row.shares.toLocaleString() : 'N/A'
                  }
                ]}
                emptyMessage="No insider trades available for this ticker"
              />
            </div>

            {/* Institutional Holders Table */}
            <div>
              <h3 className="text-xl font-semibold mb-3 text-center">Institutional Holders</h3>
              <ModernTable
                data={data.institutionalHolders}
                columns={[
                  {
                    header: "Holder",
                    accessor: "Holder"
                  },
                  {
                    header: "Shares",
                    accessor: (row) => row.Shares.toLocaleString()
                  },
                  {
                    header: "Value",
                    accessor: (row) => `$${row.Value.toLocaleString()}`
                  },
                  {
                    header: "% Held",
                    accessor: (row) => `${row.pctHeld.toFixed(2)}%`
                  }
                ]}
                emptyMessage="No institutional holders data available for this ticker"
              />
            </div>

            {/* Analyst Price Target */}
            <div className="bg-gray-800/50 p-6 rounded-lg border border-gray-700/50 text-center">
              <h3 className="text-xl font-semibold mb-4">Analyst Price Target</h3>
              {typeof data.analystPriceTarget === 'number' && data.analystPriceTarget > 0 ? (
                <div className="flex flex-col items-center">
                  <p className="text-3xl font-bold text-cyan-400">
                    ${data.analystPriceTarget.toFixed(2)}
                  </p>
                  <div className="mt-2 text-sm text-gray-400">
                    Average target based on analyst recommendations
                  </div>
                </div>
              ) : (
                <div className="py-4 text-xl text-gray-400">
                  No analyst price target available
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!isLoading && !data && !error && (
        <p className="text-gray-400 mt-4 text-center">No data available. Please enter a valid stock ticker and click "Get Data".</p>
      )}
    </div>
  );
};

export default FinanceProject2;