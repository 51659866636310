import React from 'react';
import { FaLinkedin, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import * as Dialog from '@radix-ui/react-dialog';

const Footer: React.FC = () => {
  return (
    <footer className="relative z-10 py-3">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto backdrop-blur-sm bg-gray-800/30 rounded-xl shadow-lg border border-gray-700/50 p-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-gray-400 text-sm">
              &copy; {new Date().getFullYear()} Austin Bell. All rights reserved.
            </div>
            
            <div className="flex items-center space-x-4">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="text-gray-400 hover:text-purple-400 transition-colors flex items-center text-sm">
                    <FaInfoCircle className="mr-1" /> Disclaimer
                  </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="fixed inset-0 bg-black/60 backdrop-blur-sm animate-fadeIn" />
                  <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-md w-[90vw] max-h-[85vh] overflow-auto bg-gray-800 border border-gray-700 rounded-xl shadow-xl p-6 animate-slideDownAndFade">
                    <Dialog.Title className="text-xl font-semibold mb-4 text-purple-400">Disclaimer</Dialog.Title>
                    <Dialog.Description className="text-gray-300 text-sm space-y-3">
                      <p>
                        The information provided herein is for general informational purposes only and should not be considered as financial advice. This content is not intended to be a substitute for professional financial advice, investment advice, or any other type of advice tailored to your specific financial situation.
                      </p>
                      <p>
                        Always seek the advice of a qualified financial advisor or other professional who can take into account your individual circumstances before making any financial decisions. Any actions you take based on the information presented here are strictly at your own risk.
                      </p>
                      <p>
                        Past performance is not indicative of future results. Investments carry risk, and the value of investments can go up as well as down. You may receive back less than you originally invested.
                      </p>
                      <p>
                        The author and publisher of this content are not responsible for any losses or damages resulting from the use of the information provided.
                      </p>
                    </Dialog.Description>
                    <div className="mt-6 flex justify-end">
                      <Dialog.Close asChild>
                        <button className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors">
                          Close
                        </button>
                      </Dialog.Close>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
              
              <div className="bg-gray-700 w-px h-4" />
              
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="text-gray-400 hover:text-cyan-400 transition-colors flex items-center text-sm">
                    <FaEnvelope className="mr-1" /> Contact
                  </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="fixed inset-0 bg-black/60 backdrop-blur-sm animate-fadeIn" />
                  <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-md w-[90vw] max-h-[85vh] overflow-auto bg-gray-800 border border-gray-700 rounded-xl shadow-xl p-6 animate-slideDownAndFade">
                    <Dialog.Title className="text-xl font-semibold mb-4 text-cyan-400">Contact Me</Dialog.Title>
                    <Dialog.Description className="text-gray-300">
                      <p className="mb-4">
                        Feel free to reach out to me via email at <a href="mailto:austinb1109@icloud.com" className="text-cyan-400 hover:text-cyan-300 underline">austinb1109@icloud.com</a>
                      </p>
                      <p>
                        You can also connect with me on LinkedIn.
                      </p>
                      <div className="flex space-x-4 mt-6">
                        <a href="https://www.linkedin.com/in/austin-bell-9a0082180/" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition-colors">
                          <FaLinkedin size={20} />
                        </a>
                      </div>
                    </Dialog.Description>
                    <div className="mt-6 flex justify-end">
                      <Dialog.Close asChild>
                        <button className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors">
                          Close
                        </button>
                      </Dialog.Close>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
              
              <div className="bg-gray-700 w-px h-4" />
              
              <a href="https://www.linkedin.com/in/austin-bell-9a0082180/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition-colors">
                <FaLinkedin size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
