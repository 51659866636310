import React, { useEffect, useState, useMemo } from 'react';

interface GalaxyBackgroundProps {
  className?: string;
}

const GalaxyBackground: React.FC<GalaxyBackgroundProps> = ({ className }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0.5, y: 0.5 });
  
  useEffect(() => {
    // Set initial values
    setViewportHeight(window.innerHeight || 1000);
    
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };
    
    const handleResize = () => {
      setViewportHeight(window.innerHeight || 1000);
    };
    
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({
        x: e.clientX / (window.innerWidth || 1),
        y: e.clientY / (window.innerHeight || 1)
      });
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    
    // Initial scroll position
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  // Generate stars once on component mount
  const stars = useMemo(() => {
    return Array.from({ length: 200 }).map((_, i) => ({
      id: i,
      size: Math.random() * 3 + 1,
      top: Math.random() * 100,
      left: Math.random() * 100,
      opacity: Math.random() * 0.7 + 0.3,
      duration: Math.random() * 8 + 4,
      delay: Math.random() * 10,
      color: Math.random() > 0.8 
        ? `rgba(${200 + Math.random() * 55}, ${150 + Math.random() * 105}, ${255}, ${Math.random() * 0.5 + 0.5})` // Purple/blue stars
        : Math.random() > 0.6 
          ? `rgba(${200 + Math.random() * 55}, ${200 + Math.random() * 55}, ${100 + Math.random() * 155}, ${Math.random() * 0.5 + 0.5})` // Yellow/white stars
          : `rgba(255, 255, 255, ${Math.random() * 0.5 + 0.5})`, // White stars
    }));
  }, []);
  
  // Generate nebula clouds
  const nebulaClouds = useMemo(() => {
    return Array.from({ length: 6 }).map((_, i) => ({
      id: i,
      top: 10 + Math.random() * 80, // Keep within visible area
      left: 10 + Math.random() * 80, // Keep within visible area
      width: Math.random() * 40 + 30,
      height: Math.random() * 40 + 30,
      opacity: Math.random() * 0.3 + 0.1, // Increased opacity
      color: i % 3 === 0 
        ? `rgba(${120 + Math.random() * 50}, ${50 + Math.random() * 50}, ${180 + Math.random() * 75}, 1)` // Purple nebula
        : i % 3 === 1
          ? `rgba(${50 + Math.random() * 50}, ${100 + Math.random() * 50}, ${180 + Math.random() * 75}, 1)` // Blue nebula
          : `rgba(${180 + Math.random() * 75}, ${100 + Math.random() * 50}, ${50 + Math.random() * 50}, 1)`, // Red/orange nebula
      rotation: Math.random() * 360,
      blur: Math.random() * 70 + 50,
      animationDelay: Math.random() * 60,
    }));
  }, []);
  
  // Generate cosmic dust particles
  const cosmicDust = useMemo(() => {
    return Array.from({ length: 10 }).map((_, i) => ({
      id: i,
      top: Math.random() * 100,
      left: Math.random() * 100,
      size: Math.random() * 25 + 15,
      opacity: Math.random() * 0.15 + 0.05,
      blur: Math.random() * 20 + 10,
      animationDelay: Math.random() * 10,
    }));
  }, []);
  
  // Calculate normalized scroll value for use in the render method
  const pageHeight = Math.max(
    document.body.scrollHeight || 1000, 
    document.documentElement.scrollHeight || 1000,
    3 * (viewportHeight || 1000)
  );
  const maxScroll = Math.max(pageHeight - (viewportHeight || 1000), 1);
  const normalizedScroll = Math.min(scrollPosition / maxScroll, 1);
  
  // Calculate rotation and scale based on scroll
  const rotation = normalizedScroll * 15;
  const scale = 1 + normalizedScroll * 0.2;
  
  return (
    <div 
      className={`fixed inset-0 -z-10 pointer-events-none overflow-hidden ${className || ''}`}
      style={{
        background: '#050714', // Deep space color
      }}
    >
      {/* Galaxy core with subtle parallax effect based on mouse movement */}
      <div 
        className="absolute w-full h-full transition-transform duration-1000 ease-out"
        style={{
          background: 'radial-gradient(ellipse at center, rgba(60, 40, 120, 0.5) 0%, rgba(30, 30, 70, 0.3) 40%, rgba(5, 7, 20, 0) 70%)',
          transform: `translate(${(mousePosition.x - 0.5) * -20}px, ${(mousePosition.y - 0.5) * -20}px)`,
        }}
      />
      
      {/* Cosmic dust particles */}
      {cosmicDust.map((dust) => (
        <div
          key={dust.id}
          className="absolute rounded-full animate-cosmic-drift"
          style={{
            top: `${dust.top}%`,
            left: `${dust.left}%`,
            width: `${dust.size}%`,
            height: `${dust.size}%`,
            opacity: dust.opacity,
            background: 'radial-gradient(circle, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
            filter: `blur(${dust.blur}px)`,
            animationDelay: `${dust.animationDelay}s`,
          }}
        />
      ))}
      
      {/* Nebula clouds */}
      {nebulaClouds.map((cloud) => (
        <div
          key={cloud.id}
          className="absolute rounded-full animate-nebula"
          style={{
            top: `${cloud.top}%`,
            left: `${cloud.left}%`,
            width: `${cloud.width}%`,
            height: `${cloud.height}%`,
            opacity: cloud.opacity,
            backgroundColor: cloud.color,
            filter: `blur(${cloud.blur}px)`,
            transform: `rotate(${cloud.rotation}deg) scale(${1 + normalizedScroll * 0.3})`,
            transformOrigin: 'center',
            animationDelay: `${cloud.animationDelay}s`,
          }}
        />
      ))}
      
      {/* Large central nebula */}
      <div
        className="absolute rounded-full"
        style={{
          top: '30%',
          left: '30%',
          width: '40%',
          height: '40%',
          opacity: 0.2,
          background: 'radial-gradient(ellipse at center, rgba(100, 50, 150, 0.4) 0%, rgba(50, 30, 100, 0.2) 50%, rgba(30, 20, 70, 0) 70%)',
          filter: 'blur(40px)',
          transform: `rotate(${rotation}deg) scale(${scale})`,
          transformOrigin: 'center',
        }}
      />
      
      {/* Distant stars layer - fixed */}
      <div className="absolute inset-0 overflow-hidden">
        {stars.slice(0, 100).map((star) => (
          <div
            key={star.id}
            className="absolute rounded-full"
            style={{
              width: `${star.size * 0.7}px`,
              height: `${star.size * 0.7}px`,
              top: `${star.top}%`,
              left: `${star.left}%`,
              opacity: star.opacity * 0.7,
              backgroundColor: star.color,
              boxShadow: `0 0 ${star.size * 2}px ${star.color}`,
            }}
          />
        ))}
      </div>
      
      {/* Mid-distance stars - slight parallax */}
      <div 
        className="absolute inset-0 overflow-hidden"
        style={{
          transform: `translate(${(mousePosition.x - 0.5) * -10}px, ${(mousePosition.y - 0.5) * -10}px)`,
        }}
      >
        {stars.slice(100, 150).map((star) => (
          <div
            key={star.id}
            className="absolute rounded-full"
            style={{
              width: `${star.size}px`,
              height: `${star.size}px`,
              top: `${star.top}%`,
              left: `${star.left}%`,
              opacity: star.opacity,
              backgroundColor: star.color,
              boxShadow: `0 0 ${star.size * 3}px ${star.color}`,
              animation: `twinkle ${star.duration}s infinite ${star.delay}s`,
            }}
          />
        ))}
      </div>
      
      {/* Foreground stars - more parallax */}
      <div 
        className="absolute inset-0 overflow-hidden"
        style={{
          transform: `translate(${(mousePosition.x - 0.5) * -30}px, ${(mousePosition.y - 0.5) * -30}px)`,
        }}
      >
        {stars.slice(150).map((star) => (
          <div
            key={star.id}
            className="absolute rounded-full"
            style={{
              width: `${star.size * 1.3}px`,
              height: `${star.size * 1.3}px`,
              top: `${star.top}%`,
              left: `${star.left}%`,
              opacity: star.opacity,
              backgroundColor: star.color,
              boxShadow: `0 0 ${star.size * 4}px ${star.color}`,
              animation: `twinkle ${star.duration * 0.7}s infinite ${star.delay}s`,
            }}
          />
        ))}
      </div>
      
      {/* Subtle dust particles instead of noisy cosmic dust */}
      <div 
        className="absolute inset-0 opacity-10"
        style={{
          background: 'radial-gradient(circle at 30% 40%, rgba(100, 100, 150, 0.1) 0%, rgba(30, 30, 60, 0.05) 25%, transparent 50%), radial-gradient(circle at 70% 60%, rgba(100, 80, 180, 0.1) 0%, rgba(30, 30, 60, 0.05) 25%, transparent 50%)',
          transform: `translateY(${scrollPosition * 0.05}px)`,
        }}
      />
      
      {/* Vignette effect */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          background: 'radial-gradient(ellipse at center, rgba(5, 7, 20, 0) 0%, rgba(5, 7, 20, 0.3) 70%, rgba(5, 7, 20, 0.7) 100%)',
        }}
      />
      
      {/* Subtle color overlay that changes with scroll */}
      <div 
        className="absolute inset-0 mix-blend-overlay"
        style={{
          background: `linear-gradient(${180 + normalizedScroll * 60}deg, rgba(60, 40, 120, ${0.2 + normalizedScroll * 0.2}) 0%, rgba(30, 60, 120, ${0.2 + normalizedScroll * 0.1}) 100%)`,
        }}
      />
      
      {/* Distant galaxy clusters that appear as user scrolls */}
      {normalizedScroll > 0.2 && (
        <div 
          className="absolute rounded-full blur-xl"
          style={{
            top: '70%',
            left: '20%',
            width: '15%',
            height: '15%',
            opacity: Math.min((normalizedScroll - 0.2) * 2, 0.4),
            background: 'radial-gradient(circle, rgba(150, 100, 220, 0.3) 0%, rgba(120, 80, 200, 0.1) 70%, transparent 100%)',
            transform: `scale(${1 + (normalizedScroll - 0.2) * 0.5})`,
            transition: 'opacity 1s ease-out, transform 1s ease-out',
          }}
        />
      )}
      
      {normalizedScroll > 0.5 && (
        <div 
          className="absolute rounded-full blur-xl"
          style={{
            top: '30%',
            right: '15%',
            width: '10%',
            height: '10%',
            opacity: Math.min((normalizedScroll - 0.5) * 2, 0.35),
            background: 'radial-gradient(circle, rgba(100, 150, 220, 0.3) 0%, rgba(80, 120, 200, 0.1) 70%, transparent 100%)',
            transform: `scale(${1 + (normalizedScroll - 0.5) * 0.5})`,
            transition: 'opacity 1s ease-out, transform 1s ease-out',
          }}
        />
      )}
    </div>
  );
};

export default GalaxyBackground; 