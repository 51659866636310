import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages/Home';
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/Project3';
import GalaxyBackground from './components/GalaxyBackground';
import { initGA, trackPageView } from './utils/analytics';
import './App.css';

// Initialize GA with your Measurement ID
initGA('G-540FXJ5248');

// RouteTracker component to handle route changes
const RouteTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

const App: React.FC = () => {
  return (
    <Router>
      <RouteTracker />
      <div className="app-container">
        {/* Galaxy background that changes with scroll */}
        <GalaxyBackground />
        
        {/* Animated background elements */}
        <div className="fixed inset-0 z-0 overflow-hidden pointer-events-none">
          <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-purple-500/10 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-blob"></div>
          <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-cyan-500/10 rounded-full blur-3xl transform translate-x-1/2 translate-y-1/2 animate-blob animation-delay-2000"></div>
          <div className="absolute top-1/2 left-1/2 w-1/3 h-1/3 bg-indigo-500/10 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2 animate-blob animation-delay-4000"></div>
        </div>
        
        {/* Main content */}
        <div className="flex flex-col min-h-screen w-full overflow-y-auto">
          <main className="main-content-container">
            <div className="content-wrapper">
              <div className="text-center mb-8">
                <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-cyan-400 to-indigo-400">Austin Bell</h1>
                <p className="text-xl text-gray-300 mb-6">Finance Expert</p>
                
                <div className="flex justify-center mb-8">
                  <Link to="/" className="px-6 py-3 mx-2 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-lg text-white font-medium shadow-lg hover:shadow-purple-500/20 transition-all duration-300 hover:-translate-y-1">
                    Home
                  </Link>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                <Link to="/project1" className="group flex flex-col items-center p-4 bg-gray-800/50 rounded-lg border border-gray-700/50 hover:bg-gray-700/50 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10 hover:-translate-y-1">
                  <div className="w-12 h-12 mb-3 flex items-center justify-center bg-gradient-to-br from-purple-500 to-indigo-500 rounded-full shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-gray-100 group-hover:text-white">Backtester</h3>
                  <p className="text-sm text-gray-400 text-center mt-2">Test investment strategies with historical data</p>
                </Link>
                
                <Link to="/project2" className="group flex flex-col items-center p-4 bg-gray-800/50 rounded-lg border border-gray-700/50 hover:bg-gray-700/50 transition-all duration-300 hover:shadow-lg hover:shadow-cyan-500/10 hover:-translate-y-1">
                  <div className="w-12 h-12 mb-3 flex items-center justify-center bg-gradient-to-br from-cyan-500 to-blue-500 rounded-full shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-gray-100 group-hover:text-white">Equity Dashboard</h3>
                  <p className="text-sm text-gray-400 text-center mt-2">Visualize and analyze equity performance</p>
                </Link>
                
                <Link to="/project3" className="group flex flex-col items-center p-4 bg-gray-800/50 rounded-lg border border-gray-700/50 hover:bg-gray-700/50 transition-all duration-300 hover:shadow-lg hover:shadow-indigo-500/10 hover:-translate-y-1">
                  <div className="w-12 h-12 mb-3 flex items-center justify-center bg-gradient-to-br from-indigo-500 to-purple-500 rounded-full shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-gray-100 group-hover:text-white">Portfolio Allocator</h3>
                  <p className="text-sm text-gray-400 text-center mt-2">Optimize your investment portfolio allocation</p>
                </Link>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg border border-gray-700/50 p-6">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/project1" element={<Project1 />} />
                  <Route path="/project2" element={<Project2 />} />
                  <Route path="/project3" element={<Project3 />} />
                </Routes>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
