import React from 'react';
import * as Avatar from '@radix-ui/react-avatar';

const AboutMe: React.FC = () => {
  return (
    <div className="bg-gray-800/50 rounded-lg border border-gray-700/50 p-6">
      <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
        <Avatar.Root className="inline-flex h-24 w-24 select-none items-center justify-center overflow-hidden rounded-full bg-gradient-to-br from-purple-500 to-indigo-500">
          <Avatar.Fallback className="text-white text-xl font-medium">AB</Avatar.Fallback>
        </Avatar.Root>
        
        <div className="flex-1 space-y-4">
          <h3 className="text-xl font-semibold text-purple-400">About Me</h3>
          
          <p className="text-gray-300">
            I'm a finance professional with a passion for creating innovative tools and solutions. 
            Currently working as an Auditor at a community bank in Kentucky, I specialize in financial 
            analysis, risk assessment, and investment strategies.
          </p>
          
          <p className="text-gray-300">
            This portfolio showcases some of my side projects where I combine my finance expertise with 
            web development skills. I'm particularly interested in creating tools that help investors 
            make more informed decisions through data visualization and analysis.
          </p>
          
          <div className="pt-2">
            <a 
              href="mailto:austinb1109@icloud.com" 
              className="inline-flex items-center px-4 py-2 bg-purple-500/20 hover:bg-purple-500/30 text-purple-300 rounded-lg transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              Contact Me
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
