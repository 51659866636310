import React from 'react';
import FinanceProject2 from '../components/FinanceProject2';
import Card from '../components/Card';
import * as Tabs from '@radix-ui/react-tabs';

const Project2: React.FC = () => {
  return (
    <div className="space-y-6">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-400">Equity Dashboard</h2>
        <p className="text-gray-300 max-w-2xl mx-auto">
          Visualize and analyze equity performance with interactive charts and metrics.
        </p>
      </div>
      
      <Card variant="secondary" className="p-6">
        <Tabs.Root defaultValue="dashboard" className="w-full">
          <Tabs.List className="flex border-b border-gray-700 mb-6">
            <Tabs.Trigger 
              value="dashboard" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-cyan-500 data-[state=active]:text-cyan-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Dashboard
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="instructions" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-cyan-500 data-[state=active]:text-cyan-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              Instructions
            </Tabs.Trigger>
            <Tabs.Trigger 
              value="about" 
              className="px-4 py-2 border-b-2 border-transparent data-[state=active]:border-cyan-500 data-[state=active]:text-cyan-400 text-gray-400 hover:text-gray-200 transition-colors"
            >
              About
            </Tabs.Trigger>
          </Tabs.List>
          
          <Tabs.Content value="dashboard" className="focus:outline-none">
            <FinanceProject2 />
          </Tabs.Content>
          
          <Tabs.Content value="instructions" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-cyan-400">How to Use the Dashboard</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Enter the stock symbols you want to analyze</li>
                <li>Select the date range for the analysis</li>
                <li>View the price charts and performance metrics</li>
                <li>Compare multiple stocks side by side</li>
                <li>Analyze key financial indicators</li>
              </ol>
              <p>
                The dashboard provides real-time data and visualizations to help you make informed 
                investment decisions based on historical performance and current market conditions.
              </p>
            </div>
          </Tabs.Content>
          
          <Tabs.Content value="about" className="focus:outline-none">
            <div className="space-y-4 text-gray-300">
              <h3 className="text-xl font-semibold text-cyan-400">About This Tool</h3>
              <p>
                The Equity Dashboard is a powerful tool for investors to track and analyze stock 
                performance. It provides comprehensive data visualization and analysis capabilities 
                to help you understand market trends and make better investment decisions.
              </p>
              <p>
                Features include interactive price charts, performance comparisons, technical indicators, 
                and fundamental analysis tools all in one convenient dashboard.
              </p>
              <p className="text-sm text-gray-400 italic">
                Note: This tool is for educational purposes only and should not be considered financial advice.
              </p>
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </Card>
    </div>
  );
};

export default Project2;
