import React, { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  className?: string;
  variant?: 'default' | 'primary' | 'secondary' | 'accent';
  hover?: boolean;
}

const Card: React.FC<CardProps> = ({ 
  children, 
  className = '', 
  variant = 'default',
  hover = false
}) => {
  const baseClasses = "rounded-lg border border-gray-700/50 backdrop-blur-sm";
  
  const variantClasses = {
    default: "bg-gray-800/30",
    primary: "bg-primary-900/30",
    secondary: "bg-secondary-900/30",
    accent: "bg-accent-900/30"
  };
  
  const hoverClasses = hover 
    ? "transition-all duration-300 hover:shadow-lg hover:-translate-y-1 " + 
      (variant === 'primary' 
        ? "hover:shadow-purple-500/10" 
        : variant === 'secondary' 
          ? "hover:shadow-cyan-500/10" 
          : variant === 'accent' 
            ? "hover:shadow-indigo-500/10" 
            : "hover:shadow-gray-500/10")
    : "";
  
  return (
    <div className={`${baseClasses} ${variantClasses[variant]} ${hoverClasses} ${className}`}>
      {children}
    </div>
  );
};

export default Card; 