import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';

export type ChartType = 'line' | 'area';

export interface ChartSeries {
  dataKey: string;
  name: string;
  color: string;
  type?: 'monotone' | 'linear' | 'step' | 'stepBefore' | 'stepAfter';
  strokeWidth?: number;
  fillOpacity?: number;
  dot?: boolean | object;
  activeDot?: boolean | object;
}

interface ModernChartProps {
  data: any[];
  series: ChartSeries[];
  type?: ChartType;
  height?: number;
  xAxisDataKey?: string;
  yAxisFormatter?: (value: number) => string;
  tooltipFormatter?: (value: number, name: string) => [string, string];
  tooltipLabelFormatter?: (label: string) => string;
  referenceLine?: {
    y?: number;
    label?: string;
    stroke?: string;
    strokeDasharray?: string;
  };
  className?: string;
}

const ModernChart: React.FC<ModernChartProps> = ({
  data,
  series,
  type = 'line',
  height = 400,
  xAxisDataKey = 'date',
  yAxisFormatter,
  tooltipFormatter,
  tooltipLabelFormatter,
  referenceLine,
  className = '',
}) => {
  const renderChart = () => {
    const commonProps = {
      data,
      margin: { top: 10, right: 30, left: 0, bottom: 5 },
    };

    const renderSeries = () => {
      return series.map((s) => {
        const commonSeriesProps = {
          key: s.dataKey,
          dataKey: s.dataKey,
          name: s.name,
          stroke: s.color,
          type: s.type || 'monotone',
          strokeWidth: s.strokeWidth || 2,
          dot: s.dot !== undefined ? s.dot : { r: 0 },
          activeDot: s.activeDot !== undefined ? s.activeDot : { r: 6 },
        };

        if (type === 'line') {
          return <Line {...commonSeriesProps} />;
        } else if (type === 'area') {
          return (
            <Area
              {...commonSeriesProps}
              fill={s.color}
              fillOpacity={s.fillOpacity || 0.2}
            />
          );
        }
        return null;
      });
    };

    if (type === 'area') {
      return (
        <AreaChart {...commonProps}>
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.5} />
          <XAxis
            dataKey={xAxisDataKey}
            stroke="#9CA3AF"
            tick={{ fill: '#9CA3AF', fontSize: 12 }}
            tickLine={{ stroke: '#4B5563' }}
            axisLine={{ stroke: '#4B5563' }}
          />
          <YAxis
            stroke="#9CA3AF"
            tick={{ fill: '#9CA3AF', fontSize: 12 }}
            tickLine={{ stroke: '#4B5563' }}
            axisLine={{ stroke: '#4B5563' }}
            tickFormatter={yAxisFormatter}
          />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{
              backgroundColor: 'rgba(17, 24, 39, 0.9)',
              borderColor: '#374151',
              borderRadius: '0.5rem',
              boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
              color: '#F9FAFB',
              padding: '0.75rem',
            }}
            itemStyle={{ color: '#F9FAFB' }}
            labelStyle={{ color: '#F9FAFB', fontWeight: 'bold', marginBottom: '0.5rem' }}
          />
          <Legend
            wrapperStyle={{ paddingTop: '1rem' }}
            iconType="circle"
          />
          {referenceLine && (
            <ReferenceLine
              y={referenceLine.y}
              label={referenceLine.label}
              stroke={referenceLine.stroke || '#FF4500'}
              strokeDasharray={referenceLine.strokeDasharray || '3 3'}
            />
          )}
          {renderSeries()}
        </AreaChart>
      );
    }
    
    // Default to LineChart
    return (
      <LineChart {...commonProps}>
        <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.5} />
        <XAxis
          dataKey={xAxisDataKey}
          stroke="#9CA3AF"
          tick={{ fill: '#9CA3AF', fontSize: 12 }}
          tickLine={{ stroke: '#4B5563' }}
          axisLine={{ stroke: '#4B5563' }}
        />
        <YAxis
          stroke="#9CA3AF"
          tick={{ fill: '#9CA3AF', fontSize: 12 }}
          tickLine={{ stroke: '#4B5563' }}
          axisLine={{ stroke: '#4B5563' }}
          tickFormatter={yAxisFormatter}
        />
        <Tooltip
          formatter={tooltipFormatter}
          labelFormatter={tooltipLabelFormatter}
          contentStyle={{
            backgroundColor: 'rgba(17, 24, 39, 0.9)',
            borderColor: '#374151',
            borderRadius: '0.5rem',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            color: '#F9FAFB',
            padding: '0.75rem',
          }}
          itemStyle={{ color: '#F9FAFB' }}
          labelStyle={{ color: '#F9FAFB', fontWeight: 'bold', marginBottom: '0.5rem' }}
        />
        <Legend
          wrapperStyle={{ paddingTop: '1rem' }}
          iconType="circle"
        />
        {referenceLine && (
          <ReferenceLine
            y={referenceLine.y}
            label={referenceLine.label}
            stroke={referenceLine.stroke || '#FF4500'}
            strokeDasharray={referenceLine.strokeDasharray || '3 3'}
          />
        )}
        {renderSeries()}
      </LineChart>
    );
  };

  return (
    <div className={`w-full bg-gray-800/30 p-4 rounded-lg border border-gray-700/50 ${className}`}>
      <ResponsiveContainer width="100%" height={height}>
        {renderChart()}
      </ResponsiveContainer>
    </div>
  );
};

export default ModernChart; 