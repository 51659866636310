import React, { useState } from 'react';
import axios from 'axios';
import { trackEvent } from '../utils/analytics';
import Button from './Button';
import Card from './Card';
import DatePicker from './DatePicker';
import ModernChart from './ModernChart';

interface Stock {
  ticker: string;
  shares: number;
}

interface PerformanceDataPoint {
  date: string;
  portfolioValue: number;
  benchmarkValue: number;
  // For backward compatibility with old format
  portfolioReturn?: number;
  sp500Value?: number;
  sp500Return?: number;
}

interface BacktestSummary {
  portfolioReturn: number;
  benchmarkReturn: number;
  portfolioSharpeRatio: number;
  benchmarkSharpeRatio: number;
}

const FinanceProject1: React.FC = () => {
  const [portfolio, setPortfolio] = useState<Stock[]>([{ ticker: '', shares: 0 }]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [results, setResults] = useState<BacktestSummary | null>(null);
  const [performanceData, setPerformanceData] = useState<PerformanceDataPoint[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showLongLoadingMessage, setShowLongLoadingMessage] = useState(false);
  const [dateErrors, setDateErrors] = useState<{start?: string; end?: string}>({});

  const addStock = () => {
    setPortfolio(prevPortfolio => [...prevPortfolio, { ticker: '', shares: 0 }]);
    trackEvent('backtester', 'add_stock');
  };

  const updateStock = (index: number, field: keyof Stock, value: string | number) => {
    setPortfolio(prevPortfolio => {
      const updatedPortfolio = [...prevPortfolio];
      updatedPortfolio[index] = { ...updatedPortfolio[index], [field]: value };
      return updatedPortfolio;
    });
    trackEvent('backtester', 'update_stock', JSON.stringify({ field, value }));
  };

  const removeStock = (index: number) => {
    setPortfolio(prevPortfolio => prevPortfolio.filter((_, i) => i !== index));
    trackEvent('backtester', 'remove_stock', JSON.stringify({ index }));
  };

  const isDateInFuture = (date: string): boolean => {
    const inputDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to beginning of the day for accurate comparison
    return inputDate > today;
  };

  const isDateToday = (date: string): boolean => {
    const inputDate = new Date(date);
    const today = new Date();
    return inputDate.toDateString() === today.toDateString();
  };

  // New function to get yesterday's date in YYYY-MM-DD format
  const getYesterdayDate = (): string => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  };

  const validateDates = (): boolean => {
    const errors: {start?: string; end?: string} = {};
    let isValid = true;

    if (!startDate) {
      errors.start = "Start date is required";
      isValid = false;
    } else if (isDateInFuture(startDate) || isDateToday(startDate)) {
      errors.start = "Start date must be in the past";
      isValid = false;
    }

    if (!endDate) {
      errors.end = "End date is required";
      isValid = false;
    } else if (isDateInFuture(endDate)) {
      errors.end = "End date cannot be in the future";
      isValid = false;
    } else if (isDateToday(endDate)) {
      errors.end = "End date must be at most yesterday (t-1)";
      isValid = false;
    }

    if (startDate && endDate && new Date(startDate) >= new Date(endDate)) {
      errors.end = "End date must be after start date";
      isValid = false;
    }

    setDateErrors(errors);
    return isValid;
  };

  const handleStartDateChange = (date: string) => {
    setStartDate(date);
    // Clear errors when user changes the date
    setDateErrors(prev => ({...prev, start: undefined}));
  };

  const handleEndDateChange = (date: string) => {
    setEndDate(date);
    // Clear errors when user changes the date
    setDateErrors(prev => ({...prev, end: undefined}));
  };

  const runBacktest = async () => {
    setResults(null);
    setPerformanceData([]);
    setIsLoading(true);
    setError(null);
    setShowLongLoadingMessage(false);

    // Set a timeout to show the long loading message after 5 seconds
    const longLoadingTimeout = setTimeout(() => {
      setShowLongLoadingMessage(true);
    }, 5000);

    // Validate dates before proceeding
    if (!validateDates()) {
      setIsLoading(false);
      clearTimeout(longLoadingTimeout);
      return;
    }
    
    if (!portfolio || portfolio.length === 0 || portfolio.some(stock => !stock.ticker || stock.shares <= 0)) {
      setError('Error: Please ensure all stocks have a valid ticker and positive number of shares.');
      setIsLoading(false);
      clearTimeout(longLoadingTimeout);
      return;
    }

    try {
      const response = await axios.post('/backtest', {
        portfolio,
        startDate,
        endDate
      });

      console.log('Backend response:', response.data);

      // Check if the response contains an error
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      }

      // Handle both old and new response formats
      if (response.data.performance && response.data.summary) {
        // New format
        const { performance, summary } = response.data;
        
        // Log the response to debug
        console.log("New format response:", { performance, summary });
        
        if (!performance || !Array.isArray(performance) || performance.length === 0) {
          throw new Error('Invalid or empty performance data received from the server');
        }
        
        setPerformanceData(performance);
        setResults(summary);
      } else if (response.data.performanceData) {
        // Old format
        const { 
          performanceData, 
          portfolioReturn, 
          sp500Return, 
          portfolioSharpeRatio, 
          sp500SharpeRatio 
        } = response.data;
        
        // Log the response to debug
        console.log("Old format response:", { 
          performanceData, 
          portfolioReturn, 
          sp500Return, 
          portfolioSharpeRatio, 
          sp500SharpeRatio 
        });
        
        if (!performanceData || !Array.isArray(performanceData) || performanceData.length === 0) {
          throw new Error('Invalid or empty performance data received from the server');
        }
        
        // Simply map the old format to the new format without any conversion
        const formattedData = performanceData.map(item => ({
          date: item.date,
          portfolioValue: item.portfolioValue || 0,
          benchmarkValue: item.sp500Value || 0,
          portfolioReturn: item.portfolioReturn,
          sp500Return: item.sp500Return
        }));
        
        setPerformanceData(formattedData);
        setResults({
          portfolioReturn: portfolioReturn,
          benchmarkReturn: sp500Return,
          portfolioSharpeRatio: portfolioSharpeRatio,
          benchmarkSharpeRatio: sp500SharpeRatio
        });
      } else {
        throw new Error('Invalid response format from server');
      }

      trackEvent('backtester', 'run_backtest_success', JSON.stringify({
        portfolioSize: portfolio.length,
        dateRange: `${startDate} to ${endDate}`
      }));
    } catch (err) {
      console.error('Backtest error:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      trackEvent('backtester', 'run_backtest_error', err instanceof Error ? err.message : 'Unknown error');
    } finally {
      setIsLoading(false);
      clearTimeout(longLoadingTimeout);
    }
  };

  const formatPercentage = (value: number): string => {
    // Log the exact value we're receiving to debug
    console.log("Formatting percentage value:", value);
    
    if (typeof value !== 'number') return '0.00%';
    
    // For the specific case we're seeing (-3.44 showing as -344.43%)
    // We know the backend is returning values like -3.44 that should be displayed as -3.44%
    // So we'll just format it directly without any conversion
    return value.toFixed(2) + '%';
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h3 className="text-lg font-medium text-purple-400 mb-4">Configure Your Portfolio</h3>
        
        <div className="space-y-4">
          {portfolio.map((stock, index) => (
            <div key={index} className="flex flex-wrap gap-4 items-center">
              <div className="flex-1 min-w-[200px]">
                <label className="block text-sm font-medium text-gray-300 mb-1">Stock Symbol</label>
                <input
                  type="text"
                  value={stock.ticker}
                  onChange={(e) => updateStock(index, 'ticker', e.target.value.toUpperCase())}
                  placeholder="e.g. AAPL"
                  className="w-full"
                />
              </div>
              <div className="flex-1 min-w-[200px]">
                <label className="block text-sm font-medium text-gray-300 mb-1">Shares</label>
                <input
                  type="number"
                  value={stock.shares === 0 ? '' : stock.shares}
                  onChange={(e) => updateStock(index, 'shares', parseInt(e.target.value) || 0)}
                  placeholder="Number of shares"
                  className="w-full"
                />
              </div>
              {index > 0 && (
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={() => removeStock(index)}
                  className="mt-6"
                >
                  Remove
                </Button>
              )}
            </div>
          ))}
          
          <Button 
            variant="outline" 
            size="sm" 
            onClick={addStock}
          >
            Add Stock
          </Button>
        </div>
      </Card>
      
      <Card className="p-6">
        <h3 className="text-lg font-medium text-purple-400 mb-4">Backtest Period</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            max={getYesterdayDate()}
            error={dateErrors.start}
            helperText="Select a date in the past"
          />
          
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            max={getYesterdayDate()}
            error={dateErrors.end}
            helperText="Must be at most yesterday (t-1)"
          />
        </div>
        
        <div className="mt-6">
          <Button
            variant="primary"
            onClick={runBacktest}
            isLoading={isLoading}
            disabled={
              isLoading || 
              !startDate || 
              !endDate || 
              portfolio.some(stock => !stock.ticker || stock.shares <= 0) ||
              isDateInFuture(startDate) ||
              isDateInFuture(endDate) ||
              isDateToday(endDate) ||
              new Date(startDate) >= new Date(endDate)
            }
          >
            Run Backtest
          </Button>
          
          {error && (
            <div className="mt-4 p-3 bg-red-900/30 border border-red-700 rounded-lg text-red-300 text-sm">
              {error}
            </div>
          )}
          
          {isLoading && showLongLoadingMessage && (
            <div className="mt-4 p-3 bg-blue-900/30 border border-blue-700 rounded-lg text-blue-300 text-sm">
              This may take a minute or two for portfolios with multiple stocks or long time periods...
            </div>
          )}
        </div>
      </Card>
      
      {results && (
        <Card className="p-6 mt-6">
          <h3 className="text-lg font-medium text-purple-400 mb-4">Backtest Results</h3>
          
          <div className="mt-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50">
                <div className="text-sm text-gray-400">Portfolio Return</div>
                <div className="text-2xl font-semibold text-white">
                  {formatPercentage(results.portfolioReturn)}
                </div>
              </div>
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50">
                <div className="text-sm text-gray-400">Benchmark Return</div>
                <div className="text-2xl font-semibold text-white">
                  {formatPercentage(results.benchmarkReturn)}
                </div>
              </div>
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50">
                <div className="text-sm text-gray-400">Portfolio Sharpe Ratio</div>
                <div className="text-2xl font-semibold text-white">
                  {(results.portfolioSharpeRatio || 0).toFixed(2)}
                </div>
              </div>
              <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50">
                <div className="text-sm text-gray-400">Benchmark Sharpe Ratio</div>
                <div className="text-2xl font-semibold text-white">
                  {(results.benchmarkSharpeRatio || 0).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          
          {performanceData.length > 0 && (
            <Card className="mt-6">
              <ModernChart
                data={(() => {
                  // Get initial values for normalization
                  const initialData = performanceData[0];
                  const initialPortfolioValue = initialData.portfolioValue;
                  const initialBenchmarkValue = initialData.benchmarkValue;
                  
                  // Check if we have valid initial values
                  if (initialPortfolioValue === undefined || initialBenchmarkValue === undefined) {
                    console.log("Missing initial values, using raw data");
                    return performanceData;
                  }
                  
                  // Calculate normalized percentage returns
                  return performanceData.map(data => {
                    // Calculate percentage change from initial value
                    const portfolioPercentChange = ((data.portfolioValue / initialPortfolioValue) - 1) * 100;
                    const benchmarkPercentChange = ((data.benchmarkValue / initialBenchmarkValue) - 1) * 100;
                    
                    return {
                      date: data.date,
                      portfolioValue: parseFloat(portfolioPercentChange.toFixed(2)),
                      benchmarkValue: parseFloat(benchmarkPercentChange.toFixed(2))
                    };
                  });
                })()}
                type="area"
                height={400}
                series={[
                  {
                    dataKey: "portfolioValue",
                    name: "Portfolio",
                    color: "#8B5CF6",
                    strokeWidth: 2,
                    fillOpacity: 0.1,
                    dot: { r: 3, strokeWidth: 1 },
                    activeDot: { r: 6, strokeWidth: 2 }
                  },
                  {
                    dataKey: "benchmarkValue",
                    name: "Benchmark",
                    color: "#10B981",
                    strokeWidth: 2,
                    fillOpacity: 0.05,
                    dot: { r: 3, strokeWidth: 1 },
                    activeDot: { r: 6, strokeWidth: 2 }
                  }
                ]}
                yAxisFormatter={(value) => `${value.toFixed(2)}%`}
                tooltipFormatter={(value, name) => {
                  const formattedValue = typeof value === 'number' ? value.toFixed(2) : '0.00';
                  return [`${formattedValue}%`, name];
                }}
                referenceLine={{
                  y: 0,
                  stroke: '#4B5563',
                  strokeDasharray: '3 3',
                  label: 'Break-even'
                }}
              />
            </Card>
          )}
        </Card>
      )}
    </div>
  );
};

export default FinanceProject1;